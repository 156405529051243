<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">
                                    Atualizar dados da empresa
                                </h3>
                            </div>
                            <div class="card-body col-lg-12 col-xl-6">
                                <div class="row">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col">
                                                <div class="mb-3">
                                                    <label
                                                        class="form-label required"
                                                    >
                                                        Nome Fantasia
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="company.name"
                                                        id="nameInput"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="mb-3">
                                                    <label
                                                        class="form-label required"
                                                    >
                                                        Razão Social
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="
                                                            company.legal_name
                                                        "
                                                        id="coporateNameInput"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="mb-3">
                                                    <label
                                                        class="form-label required"
                                                    >
                                                        CNPJ
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="
                                                            company.br_cnpj
                                                        "
                                                        v-maska="
                                                            '##.###.###/####-##'
                                                        "
                                                        id="cnpjInput"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="mb-3">
                                                    <label class="form-label">
                                                        IE
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="company.br_ie"
                                                        id="ieInput"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="mb-3">
                                                    <label
                                                        class="form-label required"
                                                    >
                                                        Telefone
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="
                                                            company.telephone
                                                        "
                                                        v-maska="[
                                                            '(##) ####-####',
                                                            '(##) #####-####'
                                                        ]"
                                                        id="phoneInput"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="mb-3">
                                                    <label
                                                        class="form-label required"
                                                    >
                                                        E-mail
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="company.email"
                                                        id="emailInput"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-2 mb-3">
                                            <div class="col">
                                                <h4>
                                                    Dados do endereço de
                                                    faturamento
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-3">
                                                <div class="mb-3">
                                                    <label
                                                        class="form-label required"
                                                    >
                                                        CEP
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="
                                                            company.postcode
                                                        "
                                                        @change="getAddress"
                                                        v-maska="'#####-###'"
                                                        id="cepInput"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-9">
                                                <div class="mb-3">
                                                    <label
                                                        class="form-label required"
                                                    >
                                                        Endereço
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="company.street"
                                                        id="addressInput"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="mb-3">
                                                    <label
                                                        class="form-label required"
                                                    >
                                                        Número
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="
                                                            company.street_number
                                                        "
                                                        id="numberInput"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="mb-3">
                                                    <label class="form-label">
                                                        Complemento
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="
                                                            company.complement
                                                        "
                                                        id="complementInput"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="mb-3">
                                                    <label
                                                        class="form-label required"
                                                    >
                                                        Bairro
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="
                                                            company.neighborhood
                                                        "
                                                        id="neighborhoodInput"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="mb-3">
                                                    <label
                                                        class="form-label required"
                                                    >
                                                        Cidade
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="company.city"
                                                        id="cityInput"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="mb-3">
                                                    <label
                                                        class="form-label required"
                                                    >
                                                        UF
                                                    </label>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="
                                                            company.region_code
                                                        "
                                                        v-maska="'AA'"
                                                        id="stateInput"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row align-items-center">
                                    <div class="col"></div>
                                    <div class="col-auto">
                                        <button
                                            @click="updateCompany()"
                                            class="btn btn-primary"
                                            id="saveDataInput"
                                        >
                                            Salvar dados
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import Api from "../services/api"
import Viacep from "../services/viacep"
import EventBus from "../services/event-bus"

export default {
    name: "CompanyPage",
    data() {
        return {
            company: {
                name: "",
                legal_name: "",
                br_cnpj: "",
                br_ie: "",
                street: "",
                street_number: "",
                complement: "",
                neighborhood: "",
                city: "",
                region_code: "",
                postcode: "",
                country_code: "BR",
                telephone: "",
                email: ""
            }
        }
    },
    mounted() {
        store.showSidebar = true
        store.showHeader = true

        this.getCompany()

        EventBus.emit("set-header-title", "Dados da empresa")
    },
    methods: {
        async getCompany() {
            var companyResponse = await Api.account.getCustomerCompanyData()
            if (companyResponse.data) {
                this.company = Object.assign({}, companyResponse.data)
            }
        },
        getAddress() {
            if (this.company.postcode.replace(/\D+/, "").length === 8) {
                Viacep.searchByPostcode(this.company.postcode).then(
                    (response) => {
                        this.company.street = response.data.logradouro
                        this.company.neighborhood = response.data.bairro
                        this.company.city = response.data.localidade
                        this.company.region_code = response.data.uf
                    }
                )
            }
        },
        updateCompany: async function () {
            var companyData = {
                name: this.company.name,
                legal_name: this.company.legal_name,
                br_cnpj: this.company.br_cnpj,
                br_ie: this.company.br_ie,
                street: this.company.street,
                street_number: this.company.street_number,
                complement: this.company.complement,
                neighborhood: this.company.neighborhood,
                city: this.company.city,
                region_code: this.company.region_code,
                postcode: this.company.postcode,
                country_code: this.company.country_code,
                telephone: this.company.telephone,
                email: this.company.email
            }

            try {
                await Api.account.updateCustomerCompanyData(companyData)

                EventBus.emit("message", {
                    type: "success",
                    message: "Dados atualizados!"
                })
            } catch (error) {
                EventBus.emit("message", {
                    type: "danger",
                    message:
                        "Erro ao atualizar dados da empresa: " + error.message
                })
            }
        }
    }
}
</script>
