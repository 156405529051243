<template>
    <div>
        <div>
            <div class="page-wrapper">
                <div class="page-body">
                    <div class="row">
                        <div class="col">
                            <h3>Workspaces da conta</h3>
                        </div>
                    </div>
                    <div class="row g-2">
                        <div class="col">
                            <div
                                class="card"
                                v-if="workspacesList.length === 0"
                            >
                                <div class="card-body">
                                    <div class="empty">
                                        <p class="empty-title">
                                            Nenhum workspace com acesso ainda
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="workspacesList.length">
                                <div
                                    class="col col-4"
                                    v-for="workspace in workspacesList"
                                    v-bind:key="workspace._id"
                                >
                                    <div class="card">
                                        <div class="card-body">
                                            <h3>{{ workspace.name }}</h3>
                                            <p class="text-muted">
                                                <small>
                                                    <strong
                                                        >Workspace Endpoint URL: </strong
                                                    ><br />
                                                    <a
                                                        v-bind:href="
                                                            workspace.engine_url
                                                        "
                                                        target="_blank"
                                                        >{{
                                                            workspace.engine_url
                                                        }}</a
                                                    >
                                                    &nbsp;
                                                    <button
                                                        class="btn btn-sm copyWorkspaceUrlButton"
                                                        @click="
                                                            copyText(
                                                                workspace.engine_url
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="ti ti-copy"
                                                        ></i>
                                                        Copiar
                                                    </button>
                                                </small>
                                            </p>
                                        </div>
                                        <div class="card-footer">
                                            <button
                                                @click="
                                                    redirectToWorkspace(
                                                        workspace
                                                    )
                                                "
                                                class="btn btn-azure accessWorkspace"
                                            >
                                                Acessar workspace
                                                <span>&nbsp;</span>
                                                <i
                                                    class="ti ti-circle-chevron-right"
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import EventBus from "../services/event-bus"
import Api from "../services/api"

export default {
    name: "WorkspaceListPage",
    data() {
        return {
            workspacesList: [],
            agent: null
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true

        var response = await Api.workspaces.all()
        this.workspacesList = response.data
        EventBus.emit("set-workspace", null)
        EventBus.emit("set-header-title", "Workspaces")
    },
    methods: {
        copyText(text) {
            var input = document.createElement("input")
            input.setAttribute("value", text)
            document.body.appendChild(input)
            input.select()
            var result = document.execCommand("copy")
            document.body.removeChild(input)
            return result
        },
        redirectToWorkspace(workspace) {
            var token = localStorage.getItem("token")
            if(!token){
                // reload the current page
                window.location.reload();
                return;
            }
            window.location.href =
                workspace.engine_frontend_url + "auth?token=" + token
        }
    }
}
</script>
