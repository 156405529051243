import axios from "axios"
import EventBus from "./event-bus"

const API_URL = process.env.VUE_APP_API_URL

const axiosInstance = axios.create()

axiosInstance.defaults.baseURL = API_URL
axiosInstance.interceptors.request.use((request) => {
    if (localStorage.getItem("token")) {
        request.headers["Authorization"] =
            "Bearer " + localStorage.getItem("token")
    }
    return request
})

// redirect to login page when receiving 403 response
axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response.status === 403) {
            EventBus.emit("call-logout")
        }

        EventBus.emit("ajax-request-end")
        return Promise.reject(error)
    }
)

// call event bus when ajax start
axiosInstance.interceptors.request.use((request) => {
    if (!axiosInstance._disable_loaders) {
        EventBus.emit("ajax-request-start")
    }
    return request
})

// call event bus when ajax end
axiosInstance.interceptors.response.use((response) => {
    axiosInstance._disable_loaders = false
    EventBus.emit("ajax-request-end")
    return response
})

export default {
    /**
     * Authenticate user and generate token
     * @param {String} email
     * @param {String} password
     * @returns {Promise<Object>}
     */
    authenticate(email, password) {
        return axiosInstance.post("v1/authenticate", { email, password })
    },

    refreshToken() {
        return axiosInstance.put("v1/authenticate/refresh")
    },

    recoveryPassword(email) {
        return axiosInstance.post("v1/authenticate/recovery", { email })
    },

    recoveryPasswordChange(email, token, password) {
        return axiosInstance.post("v1/authenticate/recovery/change", {
            email,
            token,
            password
        })
    },

    /**
     * Account operations
     */
    account: {
        /**
         * Get customer.company data
         */
        getCustomerCompanyData() {
            return axiosInstance.get("v1/account/company")
        },

        /**
         * Update customer.company data
         */
        updateCustomerCompanyData(data) {
            return axiosInstance.put("v1/account/company", data)
        }
    },

    /**
     * Users operations
     */
    users: {
        /**
         * Get current user data
         * @returns {Promise<Object>}
         */
        me: () => {
            return axiosInstance.get("v1/users/me")
        },

        /**
         * Update current user data
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        updateMe: (data) => {
            return axiosInstance.put("v1/users/me", data)
        },

        /**
         * Get all users
         * @returns {Promise<Object>}
         */
        all: () => {
            return axiosInstance.get("v1/users?limit=1000")
        },

        /**
         * Get user by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        get: (id) => {
            return axiosInstance.get("v1/users/" + id)
        },

        /**
         * Create user
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        create: (data) => {
            return axiosInstance.post("v1/users", data)
        },

        /**
         * Update user
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        update: (id, data) => {
            return axiosInstance.put("v1/users/" + id, data)
        },

        /**
         * Delete user
         * @param {String} id
         * @returns {Promise<Object>}
         */
        delete: (id) => {
            return axiosInstance.delete("v1/users/" + id)
        }
    },

    /**
     * Audit Logs operations
     */
    auditLogs: {
        /**
         * Verify if has audit logs on account
         * @returns {Promise<Object>}
         */
        hasAuditLogs: () => {
            return axiosInstance.get("v1/audit-logs/has-audit-logs")
        },

        /**
         * List audit logs
         * @returns {Promise<Object>}
         */
        list: (currentPage = 1, showPerPage = 20, filter = {}) => {
            var params = {
                page: currentPage,
                limit: showPerPage,
                ...filter
            }

            return axiosInstance.get("v1/audit-logs", { params })
        }
    },

    /**
     * Workspaces operations
     */
    workspaces: {
        /**
         * Get all workspaces
         * @returns {Promise<Object>}
         */
        all: (audit = true) => {
            return axiosInstance.get("v1/workspaces", {
                params: { audit }
            })
        },

        /**
         * Get workspace by id
         */
        get: (id) => {
            return axiosInstance.get("v1/workspaces/" + id)
        }
    },

    /**
     * Service Accounts
     */
    serviceAccounts: {
        oauth2: {
            requestToken(auth_type, data) {
                return axiosInstance.post(
                    "v1/service-accounts/oauth2/request-token/" + auth_type,
                    data
                )
            }
        }
    },

    reporting: {
        /**
         * Get current status of account
         * @returns {Promise<Object>}
         */
        getCurrentStatusData: () => {
            return axiosInstance.get("v1/reports/status")
        },

        /**
         * Get account history data
         * @returns {Promise<Object>}
         */
        getAccountHistoryData: (period_start, period_end) => {
            return axiosInstance.get("v1/reports/account-history", {
                params: {
                    period_start: period_start,
                    period_end: period_end,
                    timezone: "America/Sao_Paulo"
                }
            })
        },

        /**
         * Get account history data
         * @returns {Promise<Object>}
         */
        getAccountHistoryMonthlyData: () => {
            return axiosInstance.get("v1/reports/account-history/monthly")
        }
    }
}
