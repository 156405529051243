<template>
    <div class="container-tight py-4">
        <div class="card card-md">
            <div class="card-body">
                <h2 class="card-title text-center mb-4">
                    <img
                        src="@/assets/logo-blue-black.svg"
                        width="230"
                        alt="Floui.io - Integração sem limites"
                    />
                </h2>
                <div class="mb-3">
                    <label class="form-label"
                        >Digite seu e-mail de acesso:</label
                    >
                    <div class="input-icon mb-3">
                        <input
                            type="email"
                            class="form-control"
                            placeholder="Entre com o e-mail"
                            autocomplete="off"
                            v-model="email"
                            id="emailInput"
                        />
                        <span class="input-icon-addon" v-show="showLoader">
                            <div
                                class="spinner-border spinner-border-sm text-muted"
                                role="status"
                            ></div>
                        </span>
                    </div>
                </div>
                <div class="form-footer">
                    <button
                        type="submit"
                        class="btn btn-primary w-100"
                        @click="requestRecoveryPassword()"
                        id="continueButton"
                    >
                        Continuar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "RecoveryPasswordPage",
    data() {
        return {
            showPassword: false,
            showLoader: false,
            email: "",
            password: ""
        }
    },
    methods: {
        async requestRecoveryPassword() {
            try{
            if (!this.email) return
            
            await Api.recoveryPassword(this.email)
            this.$router.push("/login")

            EventBus.emit("message", {
                type: "success",
                message:
                    "Um e-mail foi enviado para você com as instruções para recuperar sua senha."
            })
            } catch (error) {
                EventBus.emit("message", {
                    type: "danger",
                    message: "Erro ao solicitar recuperação de senha."
                })
            }
        }
    },
    mounted() {
        store.showSidebar = false
        store.showHeader = false
        EventBus.emit("set-header-title", "")
    }
}
</script>
