<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-lock"></i>
                        Logs de auditoria
                    </h3>
                </div>
                <div class="col-auto">
                    <button class="btn text-red btn-sm" v-show="isFilterApplied" @click="clearFilters">
                        <i class="ti ti-x"></i>
                        Limpar filtros
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Usuário</th>
                            <th>Workspace</th>
                            <th>Recurso utilizado</th>
                            <th>Ação</th>
                            <th>Nome do recurso</th>
                            <th>ID do recurso</th>
                            <th>Data/Hora</th>
                        </tr>
                        <tr>
                            <th>
                                <input type="text" class="form-control form-control-sm" @change="updateFilter"
                                    v-model="filter.user_email" placeholder="Filtrar por usuário" />
                            </th>
                            <th>
                                <select class="form-select form-select-sm" @change="updateFilter"
                                    v-model="filter.workspace_id">
                                    <option value="">Todos os workspaces</option>
                                    <option v-for="workspace in workspaceList" v-bind:key="workspace._id"
                                        v-bind:value="workspace._id">
                                        {{ workspace.name }}
                                    </option>
                                </select>
                            </th>
                            <th>
                                <select class="form-select form-select-sm" @change="updateFilter"
                                    v-model="filter.resource_type">
                                    <option value="">Todos os recursos</option>
                                    <option v-for="resource in resourceList" v-bind:key="resource"
                                        v-bind:value="resource">
                                        {{ resource }}
                                    </option>
                                </select>
                            </th>
                            <th>
                                <select class="form-select form-select-sm" @change="updateFilter"
                                    v-model="filter.action">
                                    <option value="">Todas as ações</option>
                                    <option v-for="action in actionList" v-bind:key="action" v-bind:value="action">
                                        {{ action }}
                                    </option>
                                </select>
                            </th>
                            <th>
                                <input type="text" class="form-control form-control-sm" @change="updateFilter"
                                    v-model="filter.resource_name" placeholder="Filtrar por nome do recurso" />
                            </th>
                            <th>
                                <input type="text" class="form-control form-control-sm" @change="updateFilter"
                                    v-model="filter.resource_id" placeholder="Filtrar por ID do recurso" />
                            </th>
                            <th>
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text">De</span>
                                    <input type="datetime-local" class="form-control form-control-sm input-date-from"
                                        @change="updateFilter" v-model="filter.created_at_from" />
                                </div>
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text">Até</span>
                                    <input type="datetime-local" class="form-control form-control-sm input-date-to"
                                        @change="updateFilter" v-model="filter.created_at_to" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="auditLogsList.length">
                        <tr v-for="log in auditLogsList" v-bind:key="log._id">
                            <td>{{ log.user_email }}</td>
                            <td>{{ workspaceName(log.workspace_id) }}</td>
                            <td>{{ log.resource_type }}</td>
                            <td>{{ log.action }}</td>
                            <td>{{ log.resource_name }}</td>
                            <td>{{ log.resource_id }}</td>
                            <td>{{ formatDate(log.created_at) }}</td>
                        </tr>
                    </tbody>
                    <tbody v-if="!auditLogsList.length">
                        <tr>
                            <td colspan="100">
                                <div class="empty">
                                    Nenhum log encontrado
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer" v-if="auditLogsList.length">
                <Pagination :key="paginationKey" :showPerPage="showPerPage" :currentPage="currentPage" :total="total"
                    v-if="total" @pageChange="updatePage" />
            </div>
        </div>
    </div>
</template>

<style scoped>
small {
    color: #6c757d;
    font-size: 9px;
}

.input-date-from,
.input-date-to {
    width: 150px;
    display: inline-block;
}
</style>

<script>
import Api from "../services/api"
import { store } from "../store"
import Pagination from "../components/Pagination"

export default {
    name: "AuditLogsPage",
    components: {
        Pagination
    },
    data() {
        return {
            auditLogsList: [],
            workspace_id: null,
            paginationKey: 0,
            showPerPage: 20,
            currentPage: 1,
            workspaceList: [],
            resourceList: [
                "workspace",
                "flow",
                "form",
                "database_table",
                "database_entity",
                "dashboard",
                "datasource",
                "storage",
                "group",
                "custom_node",
                "user",
                "alert_rule",
                "service_account",
                "queue",
                "queue_job",
                "variable",
                "user",
                "workspace",
                "monitoring_logs",
                "monitoring_executions",
                "monitoring_realtime",
                "company"
            ],
            actionList: [
                "create",
                "update",
                "view",
                "view_list",
                "delete",
                "mass_delete",
                "export",
                "import",
                "duplicate",
                "copy",
                "execute",
                "debug",
                "login",
                "logout",
                "update_profile",
                "password_recovery",
                "workspace_access",
                "queue_pause",
                "queue_resume",
                "queue_clean"
            ],
            total: 0,
            filter: {
                user_email: "",
                workspace_id: "",
                resource_type: "",
                action: "",
                resource_name: "",
                resource_id: "",
                created_at_from: "",
                created_at_to: ""
            }
        }
    },
    computed: {
        isFilterApplied() {
            return Object.values(this.filter).some((value) => value)
        }
    },
    async mounted() {
        if (this.$route.query.page) {
            this.currentPage = parseInt(this.$route.query.page)
        }

        if (this.$route.query.filter) {
            this.filter = JSON.parse(this.$route.query.filter)
        }

        await this.loadWorkspaces()
        await this.loadAuditLogs()
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleString()
        },
        async loadWorkspaces() {
            await Api.workspaces.all(false).then((response) => {
                this.workspaceList = response.data
            })
        },
        async loadAuditLogs() {
            await Api.auditLogs.list(this.currentPage, 20, this.filter).then((response) => {
                this.auditLogsList = response.data.items
                this.total = response.data.total
            })
        },
        updateFilter() {
            this.currentPage = 1
            this.paginationKey = Math.random()
            this.$router.push({
                query: {
                    page: this.currentPage,
                    filter: JSON.stringify(this.filter)
                }
            })
            this.loadAuditLogs()
        },
        clearFilters() {
            this.filter = {
                user_email: "",
                workspace_id: "",
                resource_type: "",
                action_name: "",
                resource_name: "",
                resource_id: "",
                created_at_from: "",
                created_at_to: ""
            }
            this.currentPage = 1
            this.paginationKey = Math.random()
            this.$router.push({
                query: {
                    page: this.currentPage,
                    filter: ""
                }
            })
            this.loadAuditLogs()
        },
        updatePage(page) {
            this.currentPage = page
            this.paginationKey = Math.random()
            this.loadAuditLogs()
        },
        workspaceName(workspaceId) {
            const workspace = this.workspaceList.find((w) => w._id === workspaceId)
            return workspace ? workspace.name : "-"
        }
    }
}
</script>
