<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">
                                    Atualizar dados da conta de usuário
                                </h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <form v-on:submit="updateUser">
                                            <div class="row">
                                                <div class="col">
                                                    <div class="mb-3">
                                                        <label
                                                            class="form-label required"
                                                            >Nome</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="firstname"
                                                            id="firstnameInput"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="mb-3">
                                                        <label
                                                            class="form-label required"
                                                            >Sobrenome</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="lastname"
                                                            id="lastnameInput"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="mb-3">
                                                        <label
                                                            class="form-label required"
                                                            >E-mail</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="email"
                                                            id="emailInput"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row align-items-center">
                                    <div class="col"></div>
                                    <div class="col-auto">
                                        <button
                                            v-on:click="updateUser"
                                            class="btn btn-primary"
                                            id="saveDataButton"
                                        >
                                            Salvar dados
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">
                                    Atualizar senha de acesso
                                </h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <form v-on:submit="updateUser">
                                            <div class="row">
                                                <div class="col">
                                                    <div class="mb-3">
                                                        <label
                                                            class="form-label required"
                                                            >Nova senha</label
                                                        >
                                                        <input
                                                            type="password"
                                                            class="form-control"
                                                            v-model="password"
                                                            id="passwordInput"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="mb-3">
                                                        <label
                                                            class="form-label required"
                                                            >Confirme a
                                                            senha</label
                                                        >
                                                        <input
                                                            type="password"
                                                            class="form-control"
                                                            v-model="
                                                                confirm_password
                                                            "
                                                            id="confirmPasswordInput"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row align-items-center">
                                    <div class="col"></div>
                                    <div class="col-auto">
                                        <button
                                            v-on:click="updateUserPassword"
                                            class="btn btn-primary"
                                            id="savePasswordButton"
                                        >
                                            Salvar senha
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "UserProfile",
    data() {
        return {
            firstname: store.user.firstname,
            lastname: store.user.lastname,
            email: store.user.email,
            password: "",
            confirm_password: ""
        }
    },
    mounted() {
        store.showSidebar = true
        store.showHeader = true

        EventBus.emit("set-header-title", "Meu perfil")
    },
    methods: {
        updateUser: async function (e) {
            var userData = {
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email
            }

            try {
                await Api.users.updateMe(userData)

                var updatedUserData = await Api.users.me()
                EventBus.emit("updated-user", updatedUserData.data)

                EventBus.emit("message", {
                    type: "success",
                    message: "Dados atualizados!"
                })
            } catch (error) {
                EventBus.emit("message", {
                    type: "danger",
                    message:
                        "Erro ao atualizar dados do usuário: " + error.message
                })
            }

            e.preventDefault()
        },
        updateUserPassword: async function (e) {
            if (this.password != this.confirm_password) {
                EventBus.emit("message", {
                    type: "danger",
                    message: "As senhas não conferem!"
                })
                return
            }

            if (this.password.length < 6) {
                EventBus.emit("message", {
                    type: "danger",
                    message: "A senha deve ter no mínimo 6 caracteres!"
                })
                return
            }

            var userData = {
                password: this.password,
                confirm_password: this.confirm_password
            }

            try {
                await Api.users.updateMe(userData)

                EventBus.emit("message", {
                    type: "success",
                    message: "Senha atualizada!"
                })
            } catch (error) {
                EventBus.emit("message", {
                    type: "error",
                    message: "Erro ao atualizar senha: " + error.message
                })
            }

            e.preventDefault()
        }
    }
}
</script>
