<template>
    <div class="container-tight py-4">
        <form class="card card-md" autocomplete="off" v-on:submit="login">
            <div class="card-body">
                <h2 class="card-title text-center mb-4">
                    <img
                        src="@/assets/logo-blue-black.svg"
                        width="230"
                        alt="Floui.io - Integração sem limites"
                    />
                </h2>
                <div class="mb-3">
                    <label class="form-label">E-mail</label>
                    <div class="input-icon mb-3">
                        <input
                            type="email"
                            class="form-control"
                            placeholder="Entre com o e-mail"
                            autocomplete="off"
                            v-model="email"
                            v-bind:disabled="showLoader"
                            id="emailInput"
                        />
                        <span class="input-icon-addon" v-show="showLoader">
                            <div
                                class="spinner-border spinner-border-sm text-muted"
                                role="status"
                            ></div>
                        </span>
                    </div>
                </div>
                <div class="mb-2">
                    <label class="form-label">
                        Senha
                        <span class="form-label-description">
                            <a href="/recovery_password">Esqueci minha senha</a>
                        </span>
                    </label>
                    <div class="input-group input-group-flat">
                        <input
                            type="password"
                            class="form-control"
                            placeholder="Senha"
                            autocomplete="off"
                            v-model="password"
                            v-bind:disabled="showLoader"
                            v-bind="{
                                type: showPassword ? 'text' : 'password'
                            }"
                            id="passwordInput"
                        />
                        <span class="input-group-text">
                            <a
                                href="#"
                                v-on:click="toggleShowPassword"
                                class="link-secondary"
                                data-bs-toggle="tooltip"
                                id="togglePasswordButton"
                            >
                                <i class="ti ti-eye"></i>
                            </a>
                        </span>
                    </div>
                </div>
                <div class="form-footer">
                    <button
                        type="submit"
                        class="btn btn-primary w-100"
                        id="submitButton"
                    >
                        Entrar
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { store } from "../store"
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "LoginPage",
    data() {
        return {
            showPassword: false,
            showLoader: false,
            email: "",
            password: ""
        }
    },
    methods: {
        login(e) {
            this.showLoader = true
            Api.authenticate(this.email, this.password)
                .then(async (response) => {
                    try {
                        let data = JSON.parse(
                            atob(response.data.token.split(".")[1])
                        )
                        localStorage.setItem("token", response.data.token)
                        localStorage.setItem("tokenExpiration", data.exp * 1000)

                        var userResponse = await Api.users.me()
                        EventBus.emit("updated-user", userResponse.data)

                        this.$router.push("/")
                    } catch (error) {
                        this.showLoader = false
                    }
                })
                .catch((error) => {
                    EventBus.emit("message", {
                        type: "danger",
                        message:
                            "Não foi possível realizar o login. Verifique seus dados de acesso e tente novamente."
                    })
                    this.showLoader = false
                })

            e.preventDefault()
        },
        toggleShowPassword(e) {
            this.showPassword = !this.showPassword
            e.preventDefault()
        }
    },
    mounted() {
        store.showSidebar = false
        store.showHeader = false

        EventBus.emit("set-header-title", "")
    }
}
</script>
