<template>
    <h2 class="page-title">
        <a href="/">
            <img
                src="@/assets/logo-purple-white.svg"
                class="logo"
                width="160"
                alt="Floui.io - Integração sem limites"
            />
        </a>
    </h2>
</template>
