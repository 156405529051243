<template>
    <div>
        <div class="card" v-if="usersList.length">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-users"></i>
                        Usuários da conta
                    </h3>
                </div>
                <div class="col-auto">
                    <button
                        @click="newUser()"
                        data-bs-target="#modal-user-form"
                        data-bs-toggle="modal"
                        class="btn btn-primary"
                        id="newUserButton"
                    >
                        <i class="ti ti-plus"></i>
                        Criar usuário
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Sobrenome</th>
                            <th>E-mail</th>
                            <th>Status</th>
                            <th>Atualização</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in usersList" v-bind:key="user._id">
                            <td>{{ user.firstname }}</td>
                            <td>{{ user.lastname }}</td>
                            <td>{{ user.email }}</td>
                            <td>
                                <span
                                    v-if="user.active"
                                    class="badge bg-success-lt"
                                    >Ativo</span
                                >
                                <span v-else class="badge bg-danger-lt"
                                    >Inativo</span
                                >
                            </td>
                            <td>{{ formatDate(user.updated_at) }}</td>
                            <td>
                                <div v-if="user._id !== currentUser._id">
                                    <button
                                        @click="editUser(user)"
                                        class="btn btn-sm editUserButton"
                                        data-bs-target="#modal-user-form"
                                        data-bs-toggle="modal"
                                    >
                                        <i class="ti ti-pencil"></i>
                                        Editar
                                    </button>
                                    <span>&nbsp;</span>
                                    <button
                                        @click="removeUser(user)"
                                        class="btn btn-sm removeUserButton"
                                    >
                                        <i class="ti ti-trash"></i>
                                        Remover
                                    </button>
                                </div>
                                <div v-if="user._id === currentUser._id">
                                    <a
                                        href="/user/profile"
                                        class="btn btn-sm"
                                        id="editOwnUserButton"
                                    >
                                        <i class="ti ti-pencil"></i>
                                        Editar
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                Total de
                <strong>{{ usersList.length }} usuários</strong>
                <br />
            </div>
        </div>

        <div class="card" v-if="!usersList.length">
            <div class="card-header">
                <h3 class="card-title">Usuários da conta</h3>
            </div>
            <div class="card-body">
                <div class="empty">
                    <p class="empty-title">Nenhum usuário criado ainda.</p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar um novo usuário
                    </p>
                    <div class="empty-action">
                        <button
                            @click="newUser()"
                            data-bs-target="#modal-user-form"
                            data-bs-toggle="modal"
                            class="btn btn-primary"
                            id="newUserButton"
                        >
                            <i class="ti ti-plus"></i>
                            Criar usuário
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <UserForm
            :action="userFormAction"
            :data="userFormData"
            @save="saveUser"
        />
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"
import { store } from "../store"
import UserForm from "../components/UserForm"

export default {
    name: "UsersPage",
    components: {
        UserForm
    },
    data() {
        return {
            userFormAction: "create",
            userFormData: null,
            usersList: [],
            workspace_id: null,
            currentUser: {}
        }
    },
    async mounted() {
        this.workspace_id = this.$route.params.workspace_id
        this.currentUser = store.user
        await this.loadUsers()
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleString()
        },
        async loadUsers() {
            await Api.users.all().then((response) => {
                this.usersList = response.data
            })
        },
        newUser() {
            this.userFormAction = "create"
            this.userFormData = {}
        },
        editUser(user) {
            this.userFormAction = "edit"
            this.userFormData = user
        },
        async removeUser(user) {
            if (!confirm("Deseja realmente remover o usuário?")) return

            try {
                await Api.users.delete(user._id)

                EventBus.emit("message", {
                    type: "success",
                    message: "Usuário removido com sucesso"
                })

                await this.loadUsers()
            } catch (e) {
                console.log(e)

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao remover o usuário"
                })
            }
        },
        async saveUser(data) {
            try {
                if (this.userFormAction == "create") {
                    data.workspace = this.workspace_id
                    await Api.users.create(data)
                } else {
                    await Api.users.update(data._id, data)
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Usuário salvo com sucesso"
                })

                await this.loadUsers()
            } catch (e) {
                console.log(e)

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar o usuário"
                })
            }
        }
    }
}
</script>
