import axios from "axios"
import EventBus from "./event-bus"

const BUSCACEP_URL = "https://viacep.com.br/ws"
const axiosInstance = axios.create()

axiosInstance.defaults.baseURL = BUSCACEP_URL

// call event bus when ajax start
axiosInstance.interceptors.request.use((request) => {
    EventBus.emit("ajax-request-start")
    return request
})

// call event bus when ajax end
axiosInstance.interceptors.response.use((response) => {
    EventBus.emit("ajax-request-end")
    return response
})

export default {
    /**
     * Busca por um CEP no serviço ViaCEP
     * @param {String} cep
     * @returns {Promise<Object>}
     */
    searchByPostcode(postcode) {
        return axiosInstance.get(postcode + "/json")
    }
}
